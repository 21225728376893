.mainWrapper {
  display: grid;
  grid-template-rows: 1fr 4fr 2fr;
  grid-template-areas:
    "top"
    "middle"
    "bottom";
  visibility: hidden;
  position: absolute;
  background-color: rgb(0, 0, 0);
  color: rgb(35, 35, 63);
  border: 1vw solid white;
  top: 7.5%;
  left: 12.5%;
  width: 75vw;
  height: 65vh;
}

#in {
  opacity: 1;
  transition: opacity 0.15s ease;
}

#out {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.x {
  display: flex;
  flex: 1 1 1;
  grid-area: top;
  justify-content: flex-end;
  align-items: center;
}

.closeButton {
  border: none;
  background-color: white;
  border-radius: 20px;
  color: black;

  height: 5vh;
  width: 6vh;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3%;

  font-size: 5vh;

  cursor: pointer;
}

.closeButton:hover {
  background-color: yellow;
}

.mainBody {
  height: 100%;
  grid-area: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lowerBody {
  grid-area: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainWrapper::-webkit-scrollbar {
  display: none;
}

.input {
  background-color: black;
  color: white;
  width: 75%;
  font-size: 4vh;
  padding-left: 5%;
}

.input::placeholder {
  color: white;
}

.resultWrapper {
  width: 75%;
  height: 20%;
  /* display: flex;
    justify-content: center;
    align-items: center; */
  cursor: pointer;
  overflow-y: scroll;
}

.resultWrapper::-webkit-scrollbar {
  display: none;
}

.h3 {
  color: white;
  text-align: center;
  padding: 0;
  margin: 0;
}

.lowerBodyButton {
  height: 50%;
  width: 40%;
  border: none;
  background-color: white;
  border-radius: 20px;
  color: black;
}

.lowerBodyButton:hover {
  background-color: yellow;
}

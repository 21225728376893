.cartWrapper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100vw;
  height: 55vh;
  background-color: black;
  overflow-y: scroll;
}

.itemsWrapper {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.noItems {
  align-self: center;
  margin: auto;
}

.item {
  border-bottom: 1px solid white;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  grid-template-areas: "one two three four";
  box-sizing: border-box;
  width: 100%;
  color: white;
}
.itemName,
.itemPrice,
.itemQuantity {
  text-align: center;
  align-self: center;
}
.itemName {
  grid-area: one;
}
.itemPrice {
  grid-area: two;
  border-left: 1px dotted lightblue;
}
.itemQuantity {
  grid-area: three;
  border-left: 1px dotted lightblue;
}

.increment {
  grid-area: four;
  display: flex;
}

.minus,
.plus {
  flex: 1;
  background-color: white;
  color: black;
  font-size: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.minus:hover,
.plus:hover {
  background-color: black;
  color: white;
}

#cartUp {
  transform: translate3d(0, 0, 0);
  transition: transform 0.125s ease;
}
#cartDown {
  transform: translate3d(0, 50vh, 0);
  transition: transform 0.125s ease;
}

#root,
.App {
  height: 100%;
  width: 100%;
}

body {
  height: 100vh;
  width: 100vw;
}
